/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 Osiris-Rex03.glb 
*/

import React, { useRef,useState } from 'react'
import { useGLTF ,Outlines, Html , Float} from '@react-three/drei'
import LocationMarker from "./LocationMarker";

export function OsirisRex(props) {
  
  const { nodes, materials } = useGLTF('/Osiris-Rex03.glb')
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(false);
  const [isExpanded4, setIsExpanded4] = useState(false);

  return (
   


    <group {...props} dispose={null}>


      <mesh geometry={nodes._root.geometry} material={materials.transparent}>
         
        <mesh geometry={nodes.BUS.geometry} material={materials.foil_silver} position={[-0.065, 0.444, 0.021]}>
       
          <group position={[0.065, -1.361, -0.021]}>
            <mesh geometry={nodes.Sphere001.geometry} 
            material={materials.AA_bronze}


            onPointerOver={() => {
              if (!isExpanded4) setIsExpanded4(true);
            }}
            onPointerLeave={() => {
              if (isExpanded4) setIsExpanded4(false);
            }} 

            >
            <Outlines thickness={0.01} color="blue" />

            </mesh>

            {isExpanded4 && (
                  <Html>
                    <div className="locationMarker"
                      style={{
                        paddingLeft: "2rem",
                        paddingRight: "2rem",
                        paddingTop: "1rem",
                        paddingBottom: "1rem",
                        background: "rgba(0,0,0,0.5)",
                        color: "white",
                        minWidth: "30vw",
                        border: "1px solid",
                      }}
                    >
          
                      <h3>Thrusters</h3>
                      <p>OSIRIS-REx has thrusters to adjust its trajectory.</p>
                    </div>
                  </Html>
                )}

            <mesh geometry={nodes.Sphere001_1.geometry} 
            material={materials.AA_black_glossy}>
            <Outlines thickness={0.01} color="blue" />
             </mesh>

            <mesh geometry={nodes.bronze_dome_connector.geometry} 
            material={materials.AA_bronze} 
            position={[0, 0.917, 0]}>
              
              
            </mesh> 
          </group>


          <mesh geometry={nodes.Bus_plate_mounting.geometry} material={materials.AA_Array_final} position={[0.192, -0.63, 3.658]}>
            <mesh geometry={nodes.heatshield_mount.geometry} material={materials.AA_Array_final} />
            <mesh geometry={nodes.Tagsam_housing.geometry} material={materials.AA_Array_final} position={[-1.115, 1.724, -3.68]}>
              <mesh geometry={nodes.Tagsam_housing_cover.geometry} material={materials.AA_Array_final} position={[-0.119, 0.202, 0.003]} />
            </mesh>
          </mesh>


          <mesh geometry={nodes.cams.geometry} material={materials.AA_Array_final} position={[-0.603, 0.954, 1.401]} />
          <mesh geometry={nodes.dunno.geometry} material={materials.Material} position={[1.188, 1.695, -1.475]} />
          <mesh geometry={nodes.Gmbal_Base.geometry} material={materials.AA_Array_final} position={[1.514, -0.845, -0.012]}>
            
            <mesh geometry={nodes.Ginbal_Pivot.geometry} 
            material={materials.AA_Array_final} 
            position={[0.01, 0.199, 0]}>
              
              <group position={[-1.333, 0.004, 0]}
              onPointerOver={() => {
                if (!isExpanded3) setIsExpanded3(true);
              }}
              onPointerLeave={() => {
                if (isExpanded3) setIsExpanded3(false);
              }} >

            {isExpanded3 && (
                  <Html>
                    <div className="locationMarker"
                      style={{
                        paddingLeft: "2rem",
                        paddingRight: "2rem",
                        paddingTop: "1rem",
                        paddingBottom: "1rem",
                        background: "rgba(0,0,0,0.5)",
                        color: "white",
                        minWidth: "30vw",
                        border: "1px solid",
                      }}
                    >
          
                      
                      <h3>Solar Arrays</h3>
                      <p>This is the part of the spacecraft that actually touched Bennu, slapping its surface at the same time that it sucked up loose rocks with nitrogen gas</p>

                      </div>
                  </Html>
                  )}

                <mesh geometry={nodes.Cube004.geometry} 
                material={materials.AA_Array_final} 
                scale={[1,5,1]}>
                <Outlines thickness={0.01} color="blue" />
                </mesh>

                <mesh geometry={nodes.Cube004_1.geometry} 
                material={materials.AA_gold_metal} 
                scale={[1,5,1]}>
                <Outlines thickness={0.01} color="blue" />
                </mesh>


                <mesh geometry={nodes.Cube004_2.geometry} 
                material={materials.shiny_panels} 
                scale={[1,5,1]}
                >
                <Outlines thickness={0.01} color="blue" />
                </mesh>

                <mesh geometry={nodes.array_gobstopper.geometry} material={materials.AA_black_metal} 
                position={[-1.509, -0.012, 5.23]}
                scale={[1,5,1]} >
                  <Outlines thickness={0.01} color="blue" />
                </mesh>

                

                


              
              </group>

              
            </mesh>
          </mesh>

        <mesh geometry={nodes.heatshield.geometry} 
        material={materials['Material.001']} 
        position={[0.192, -0.63, 3.658]}
        

        onPointerOver={() => {
          if (!isExpanded) setIsExpanded(true);
        }}
        onPointerLeave={() => {
          if (isExpanded) setIsExpanded(false);
        }}
        >

        <Outlines thickness={0.05} color="blue" />

        {isExpanded && (
                  <Html>
                    <div className="locationMarker"
                      style={{
                        paddingLeft: "2rem",
                        paddingRight: "2rem",
                        paddingTop: "1rem",
                        paddingBottom: "1rem",
                        background: "rgba(0,0,0,0.5)",
                        color: "white",
                        minWidth: "30vw",
                        border: "1px solid",
                      }}
                    >
          
                      <h3>Sample Return Capsule (SRC) </h3>
                      <p>The OSIRIS-REx mission successfully gathered a sample from Bennu and stowed it in the SRC.</p>
                    </div>
                  </Html>
                )}





            <mesh geometry={nodes.heatshield_bits.geometry} material={materials.AA_Array_final} />
          </mesh>
          <mesh geometry={nodes.HGA.geometry} material={materials['white krinkle']} position={[2.335, 0.322, -0.022]}>
            <mesh geometry={nodes.HGA_frame.geometry} material={materials.AA_Array_final} position={[-0.976, -0.004, -0.004]} />
          </mesh>
          <group position={[2.335, 0.322, -0.022]}>
            <mesh geometry={nodes.Cylinder006.geometry} material={materials['white krinkle']} />
            <mesh geometry={nodes.Cylinder006_1.geometry} material={materials.foil_silver} />
          </group>
          <group position={[0.237, 0.97, -1.383]}>
            <mesh geometry={nodes.Cube007.geometry} material={materials.AA_Array_final} />
            <mesh geometry={nodes.Cube007_1.geometry} material={materials.foil_silver} />
          </group>
          <mesh geometry={nodes.instrument2.geometry} material={materials.AA_Array_final} position={[-0.332, 1.305, -1.433]} />
          <mesh geometry={nodes.instrument3.geometry} material={materials.AA_Array_final} position={[0.061, 0.948, -0.854]} />
          <group position={[0.275, 1.18, 1.09]}>
            <mesh geometry={nodes.Cube010.geometry} material={materials.AA_Array_final} />
            <mesh geometry={nodes.Cube010_1.geometry} material={materials.foil_silver} />
          </group>
          <mesh geometry={nodes.instrument5.geometry} material={materials.AA_Array_final} position={[1.135, -1.05, -1.357]} />
          <mesh geometry={nodes.instrument6.geometry} material={materials.AA_Array_final} position={[-0.685, 1.359, -1.411]} />
          <mesh geometry={nodes.instrument7.geometry} material={materials.AA_Array_final} position={[1.277, 1.073, -0.384]} />
          <mesh geometry={nodes.instrument8.geometry} material={materials.AA_Array_final} position={[-1.087, -0.914, -0.894]} />
          <mesh geometry={nodes.LGA.geometry} material={materials.AA_Array_final} position={[1.624, -1.007, -1.289]} />
          <mesh geometry={nodes.LGA2.geometry} material={materials.AA_Array_final} position={[-1.305, 0.992, 0.559]} />
          <mesh geometry={nodes.Ovirs.geometry} material={materials.AA_Array_final} position={[-1.761, 0.767, -0.712]} />
          <group position={[-0.773, 0.889, -1.115]}>
            <mesh geometry={nodes.Cylinder013.geometry} material={materials.AA_bronze} />
            <mesh geometry={nodes.Cylinder013_1.geometry} material={materials.AA_mirror} />
            <mesh geometry={nodes.Cylinder013_2.geometry} material={materials.AA_gold_metal} />
          </group>
          <group position={[-1.313, 0.995, -0.857]}>
            <mesh geometry={nodes.Cylinder014.geometry} material={materials.AA_Array_final} />
            <mesh geometry={nodes.Cylinder014_1.geometry} material={materials.AA_bronze} />
          </group>
          <mesh geometry={nodes.side_bits.geometry} material={materials.AA_Array_final} position={[-1.042, 0.788, -1.576]} />
          <mesh geometry={nodes.star_trackers.geometry} material={materials.AA_Array_final} position={[-1.622, 0.551, 0.977]} />

          <mesh geometry={nodes.Tagsam.geometry} 
          material={materials.AA_Array_final} 
          position={[-0.891, 0.925, 0.299]}>
            

            <mesh geometry={nodes.Tagsam_rod_bottom.geometry} 
            material={materials.AA_Array_final} 
            position={[-0.074, 0.007, -0.033]}

            onPointerOver={() => {
              if (!isExpanded2) setIsExpanded2(true);
            }}
            onPointerLeave={() => {
              if (isExpanded2) setIsExpanded2(false);
            }}
            >
            <Outlines thickness={0.05} color="blue" />

            {isExpanded2 && (
                  <Html>
                    <div className="locationMarker"
                      style={{
                        paddingLeft: "2rem",
                        paddingRight: "2rem",
                        paddingTop: "1rem",
                        paddingBottom: "1rem",
                        background: "rgba(0,0,0,0.5)",
                        color: "white",
                        minWidth: "30vw",
                        border: "1px solid",
                      }}
                    >
          
                      <h3>Touch-and-Go Sample Acquisition Mechanism (TAGSAM)</h3>
                      <p>This is the part of the spacecraft that actually touched Bennu, slapping its surface at the same time that it sucked up loose rocks with nitrogen gas</p>
                    </div>
                  </Html>
                )}
            




              <mesh geometry={nodes.Tagsam_rod_top.geometry} material={materials.AA_Array_final} position={[-1.044, 1.458, -0.275]}>
              <Outlines thickness={0.05} color="blue" />

                <mesh geometry={nodes.tagsam_head.geometry} material={materials.AA_Array_final} position={[-0.405, 1.909, -0.011]} />
                <Outlines thickness={0.05} color="blue" />
              </mesh>
            </mesh>
          </mesh>
          <mesh geometry={nodes.Thrusters.geometry} material={materials.AA_Array_final} position={[-1.625, -0.923, -1.52]} />
          <group position={[1.724, -0.839, 1.33]}>
            <mesh geometry={nodes.Cube013.geometry} material={materials.AA_black_metal} />
            <mesh geometry={nodes.Cube013_1.geometry} material={materials.AA_bronze} />
          </group>
          <mesh geometry={nodes.Tracker.geometry} material={materials.AA_Array_final} position={[1.543, -1.008, 1.246]} />
          <mesh geometry={nodes.wtf_pillars.geometry} material={materials.AA_Array_final} position={[0.086, -1.095, -1.163]} />
        </mesh>
      </mesh>
    </group>





  )
}

useGLTF.preload('/Osiris-Rex03.glb')
